.editor {
    box-sizing: border-box;
    cursor: text;
    padding: 16px;
    background: #fefefe;
    overflow: auto;
    max-height: 80px;
    border-radius:30px;
}

.editor :global(.public-DraftEditor-content) {
    min-height: 30px;
}

  
