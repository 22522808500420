/* The actual timeline (the vertical ruler) */
.timeline {
    position: relative;
    max-width: 1200px; 
}
  
/* The actual timeline (the vertical ruler) */
.timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: rgb(204, 214, 221);
    top: 0;
    bottom: 0;
    margin-left: -1px;
}

/* Container around content */
.timeline-container {
    padding: 0px 13px 20px;
    position: relative;
    background-color: inherit;
    width: "100%";
}

.timelineAvatar {
    position: absolute;
    z-index: 2;
    left: -20px;
    width: 50px;
    height: 50px;
}

/* Fix the circle for containers on the right side */
.right::after {
    left: -16px;
}

/* The actual content */
.contentNote  {
    padding: 15px 10px 10px 15px;
    background-color: white;
    position: relative;
    margin-left: 15px;
    border-radius: 10px;
}

/* The actual content */
.contentHeader  {
    padding: 7px 7px 0px 15px;
    position: relative;
    border-radius: 1px;
}


/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
    /* Place the timelime to the left */
    .timeline::after {
        left: 30px;
    }
}
