html,
body {
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #f5f5f5;
}
.container {
  
}
.nav-container {
  margin-left: auto;
}
.nav-container svg {
  color: #fff;
}
a {
  text-decoration: none;
}